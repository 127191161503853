// components/AdminUserManager.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
} from "@mui/material";
import { httpsCallable, getFunctions } from "firebase/functions";
import { firestore } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";

const AdminUserManager = () => {
  const { uid } = useParams();
  const [firestoreData, setFirestoreData] = useState(null);
  const [claims, setClaims] = useState({
    editor: false,
    publisher: false,
    god: false,
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const functions = getFunctions();

  // Fetch Firestore user data and auth custom claims via cloud function
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        // Fetch Firestore document
        const userDocRef = doc(firestore, "users", uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setFirestoreData(userDocSnap.data());
        } else {
          setError(`User document for UID ${uid} not found.`);
        }
        // Fetch auth custom claims via cloud function "getUserClaims"
        const getUserClaims = httpsCallable(functions, "getUserClaims");
        const result = await getUserClaims({ uid });
        if (result.data && result.data.claims) {
          setClaims({
            editor: result.data.claims.editor || false,
            publisher: result.data.claims.publisher || false,
            god: result.data.claims.god || false,
          });
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uid, functions]);

  const handleToggle = (field) => {
    setClaims((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSaveClaims = async () => {
    setUpdating(true);
    try {
      // Update auth custom claims via cloud function "updateUserClaims"
      const updateUserClaims = httpsCallable(functions, "updateUserClaims");
      await updateUserClaims({ uid, claims });
      alert("User claims updated successfully.");
    } catch (err) {
      console.error("Error updating user claims:", err);
      alert("Failed to update user claims.");
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        User Manager
      </Typography>
      {firestoreData && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Firestore User Details</Typography>
          <pre>{JSON.stringify(firestoreData, null, 2)}</pre>
        </Paper>
      )}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Edit Custom Claims
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={claims.editor}
                onChange={() => handleToggle("editor")}
              />
            }
            label="Editor"
          />
          <FormControlLabel
            control={
              <Switch
                checked={claims.publisher}
                onChange={() => handleToggle("publisher")}
              />
            }
            label="Publisher"
          />
          <FormControlLabel
            control={
              <Switch
                checked={claims.god}
                onChange={() => handleToggle("god")}
              />
            }
            label="God"
          />
        </FormGroup>
        <Button
          variant="contained"
          onClick={handleSaveClaims}
          disabled={updating}
          sx={{ mt: 2 }}
        >
          {updating ? "Updating..." : "Save Claims"}
        </Button>
      </Paper>
    </Box>
  );
};

export default AdminUserManager;
