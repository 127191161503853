// components/AdminLayout.js
import React, { useEffect, useState } from "react";
import { NavLink, Routes, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  List,
  ListItemText,
  Toolbar,
  Drawer,
  ListItemButton,
} from "@mui/material";
import AdminDashboard from "./AdminDashboard";
import AdminJsonViewer from "./AdminJsonViewer";
import AdminUserManager from "./AdminUserManager";
import { Helmet } from "react-helmet";
import TopBar from "../TopBar";

const drawerWidth = 240;

const AdminLayout = () => {
  const claims = useSelector((state) => state.user.claims);
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!claims) {
      return; // Do nothing while claims are not yet loaded
    }

    // Check admin privileges using Redux claims
    if (claims?.god === true) {
      setIsAuthorized(true);
    } else {
      navigate("/404"); // Redirect unauthorized users
    }
  }, [claims, navigate]);

  if (!isAuthorized) {
    return null; // Show nothing while checking authorization
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Helmet>
        <title>Admin panel</title>
      </Helmet>
      <Box
        sx={{
          position: "fixed",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: "100vw",
        }}
      >
        <TopBar />
      </Box>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItemButton component={NavLink} to="/admin" end>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to="/admin/json/levels/sampleLevelSlug"
            >
              <ListItemText primary="JSON Viewer" />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/admin/user/sampleUserId">
              <ListItemText primary="User Manager" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route
            path="json/:type/:documentSlug"
            element={<AdminJsonViewer />}
          />
          <Route path="user/:uid" element={<AdminUserManager />} />
          <Route path="*" element={<AdminDashboard />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminLayout;
