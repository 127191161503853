// components/AdminDashboard.js
import React, { useState } from "react";
import { Button, Typography, Box, Paper } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [updates, setUpdates] = useState(null);

  const handlePopulateTranslations = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const populateTranslations = httpsCallable(
        functions,
        "populateTranslations"
      );
      const result = await populateTranslations();
      setUpdates(result.data.updates);
    } catch (error) {
      console.error("Error populating translations:", error);
      alert("Failed to populate translations.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to the admin panel. This section is restricted to authorized
        users only.
      </Typography>
      <Typography variant="h5" gutterBottom>
        Functions
      </Typography>
      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6">Populate Translations</Typography>
        <Button
          variant="contained"
          onClick={handlePopulateTranslations}
          disabled={loading}
        >
          {loading ? "Processing..." : "Populate Translations"}
        </Button>
        {updates && (
          <Box mt={2}>
            <Typography variant="subtitle1">Updates Summary:</Typography>
            <pre>{JSON.stringify(updates, null, 2)}</pre>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AdminDashboard;
