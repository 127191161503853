import { compare } from 'compare-versions';
export default class Compatibility {
  static get version() {
    return '0.18';
  }

  static upgrade(data) {
    const version = data.gameEngineVersion ? data.gameEngineVersion : data.version;
    if (!('version' in data))
      data = from_0_0_to_0_1(data);
    if (compare(version, '0.2', '<') )
      data = from_0_1_to_0_2(data);
    if (compare(version, '0.3', '<') )
      data = from_0_2_to_0_3(data);
    if (compare(version, '0.4', '<') )
      data = from_0_3_to_0_4(data);
    if (compare(version, '0.5', '<') )
      data = from_0_4_to_0_5(data);
    if (compare(version, '0.6', '<') )
      data = from_0_5_to_0_6(data);
    if (compare(version, '0.7', '<') )
      data = from_0_6_to_0_7(data);
    if (compare(version, '0.8', '<') )
      data = from_0_7_to_0_8(data);
    if (compare(version, '0.9', '<') )
      data = from_0_8_to_0_9(data);
    if (compare(version, '0.10', '<') )
      data = from_0_9_to_0_10(data);
    if (compare(version, '0.11', '<') )
      data = from_0_10_to_0_11(data);
    if (compare(version, '0.12', '<') )
      data = from_0_11_to_0_12(data);
    if (compare(version, '0.13', '<') )
      data = from_0_12_to_0_13(data);
    if (compare(version, '0.14', '<') )
      data = from_0_13_to_0_14(data);
    if (compare(version, '0.15', '<') )
      data = from_0_14_to_0_15(data);
    if (compare(version, '0.16', '<') )
      data = from_0_15_to_0_16(data);
    if (compare(version, '0.17', '<') )
      data = from_0_16_to_0_17(data);
    if (compare(version, '0.18', '<') )
      data = from_0_17_to_0_18(data);
    return data;
  }
}

function recursive(data, foo) {
  if (!data)
    return;

  foo(data);

  if (data.constructor === Array) {
    data.forEach((el) => {
      recursive(el, foo);
    })
  }
  else if (data.constructor === Object) {
    Object.entries(data).forEach(([key, el]) => {
      recursive(el, foo);
    })
  }
}

function from_0_0_to_0_1(data) {
  recursive(data, (el) => {
    // rename 'machineBase' => 'machine'
    if (el.constructor === Object && el.asset === 'machineBase')
      el.asset = 'machine';

    // rename 'availableBlocks' => 'available-blocks'
    if (el.constructor === Object && 'availableBlocks' in el) {
      el['available-blocks'] = el['availableBlocks'];
      delete el['availableBlocks'];
    }

    // Rename key => user-input
    if (el.constructor === Object && el.triggerName === 'on-key')
      el.triggerName = 'user-input-change';
    if (el.constructor === Object && 'key' in el) {
        el['input'] = el['key'];
        delete el['key'];
      }

  })

  data.version = '0.1';
  return data;
}

function from_0_1_to_0_2(data) {
  recursive(data, (el) => {
    if (!(el.constructor === Object && 'triggerName' in el))
      return;

    // Remove trigger 'on-' prefix
    if (el.constructor === Object && el.triggerName.startsWith('on-'))
      el.triggerName = el.triggerName.substring(3);
    // Rename other triggers
    if (el.constructor === Object && el.triggerName === 'down')
      el.triggerName = 'button-down';
    if (el.constructor === Object && el.triggerName === 'up')
      el.triggerName = 'button-up';
    if (el.constructor === Object && el.triggerName === 'intersection')
      el.triggerName = 'block-enter';
  })

  data.version = '0.2';
  return data;
}

function from_0_2_to_0_3(data) {
  recursive(data, (el) => {
    if (!(el.constructor === Object && 'triggerName' in el))
      return;

    // Rename triggers
    if (el.constructor === Object && el.triggerName === 'variable-change')
      el.triggerName = 'variable';
    if (el.constructor === Object && el.triggerName === 'user-input-change')
      el.triggerName = 'user-input';
  })

  data.version = '0.3';
  return data;
}

function from_0_3_to_0_4(data) {
  recursive(data, (el) => {
    // rename 'wheel' => 'wheelBike'
    if (el.constructor === Object && el.asset === 'wheel')
      el.asset = 'wheelBike';
  })

  data.version = '0.4';
  return data;
}

function from_0_4_to_0_5(data) {
  recursive(data, (el) => {
    // set 'wheel.fixed = true'
    if (el.constructor === Object && 'asset' in el && el.asset.includes('heel'))
      el.parameters = { fixed: true};
  })

  data.version = '0.5';
  return data;
}

function from_0_5_to_0_6(data) {
  recursive(data, (el) => {
    if (el.constructor === Object && el.asset === 'pivot') {
      if (el.events !== undefined)
        el.events.forEach((event) => {
          if (event.actionName === 'set-position')
            event.actionName = 'set-angle';
        });
    }
  })

  data.version = '0.6';
  return data;
}

function from_0_6_to_0_7(data) {
  recursive(data, (el) => {
    // rename 'triangle' => 'prism'
    if (el.constructor === Object && el.asset === 'triangle')
      el.asset = 'prism';
  })

  data.version = '0.7';
  return data;
}

function from_0_7_to_0_8(data) {
  recursive(data, (el) => {
    // rotate wings
    if (el.constructor === Object && el.asset === 'wing') {
      el.rotation = 'rotation' in el ? el.rotation : [0, 0, 0];
      el.rotation[1] += 1.5708;
    }

    // rename up to +y slot
    if (el.constructor === Object && [
      'cube', 'machine',
      'pipeBent2x2', 'pipeBent3x3', 'pipeBent4x4', 'bentPipe5x5', 'bentPipe6x6', 'bentPipe7x7', 'bentPipe8x8', 'wheelCogBig', 'wheelCar', 'decoupler', 'piston', 'pivot', 'roller', 'wheelCogSmall', 'pipeStraight1', 'pipeStraightFifth', 'pipeStraightHalf', 'wheelTrain', 'wheelTrainInverted', 'twist',
    ].includes(el.asset) && el.slots?.up) {
      el.slots['+y'] = {};
      Object.assign(el.slots['+y'], el.slots.up);
      delete el.slots.up;
    }

    // Rename wheelBikeSlots
    if (el.constructor === Object && el.asset === 'wheelBike') {
      if (el.slots?.center) {
        el.slots['+y'] = {};
        Object.assign(el.slots['+y'], el.slots.center);
        delete el.slots.center;
      }
      if (el.slots?.tUp) {
        el.slots['+x'] = {};
        Object.assign(el.slots['+x'], el.slots.tUp);
        delete el.slots.tUp;
      }
      if (el.slots?.tDown) {
        el.slots['-x'] = {};
        Object.assign(el.slots['-x'], el.slots.tDown);
        delete el.slots.tDown;
      }
      if (el.slots?.tLeft) {
        el.slots['+z'] = {};
        Object.assign(el.slots['+z'], el.slots.tLeft);
        delete el.slots.tLeft;
      }
      if (el.slots?.tRight) {
        el.slots['-z'] = {};
        Object.assign(el.slots['-z'], el.slots.tRight);
        delete el.slots.tRight;
      }
      if (el.slots?.bUp) {
        el.slots['+xi'] = {};
        Object.assign(el.slots['+xi'], el.slots.bUp);
        delete el.slots.bUp;
      }
      if (el.slots?.bDown) {
        el.slots['-xi'] = {};
        Object.assign(el.slots['-xi'], el.slots.bDown);
        delete el.slots.bDown;
      }
      if (el.slots?.bLeft) {
        el.slots['+zi'] = {};
        Object.assign(el.slots['+zi'], el.slots.bLeft);
        delete el.slots.bLeft;
      }
      if (el.slots?.bRight) {
        el.slots['-zi'] = {};
        Object.assign(el.slots['-zi'], el.slots.bRight);
        delete el.slots.bRight;
      }
    }

    // Rename prism
    if (el.constructor === Object && el.asset === 'prism') {
      if (el.slots?.left) {
        el.slots['+x'] = {};
        Object.assign(el.slots['+x'], el.slots.left);
        delete el.slots.left;
      }
      if (el.slots?.right) {
        el.slots['-x'] = {};
        Object.assign(el.slots['-x'], el.slots.right);
        delete el.slots.right;
      }
    }

    // Rename triangle
    if (el.constructor === Object && el.asset === 'triangle') {
      if (el.slots?.top) {
        el.slots['+y'] = {};
        Object.assign(el.slots['+y'], el.slots.top);
        delete el.slots.top;
      }
      if (el.slots?.back) {
        el.slots['-x'] = {};
        Object.assign(el.slots['-x'], el.slots.back);
        delete el.slots.back;
      }
    }

    // rename and rotate machine.left to machine.+x
    if (el.constructor === Object && ['cube', 'machine', 'pipeT', 'roller'].includes(el.asset) && el.slots?.left) {
      el.slots.left.rotation = 'rotation' in el.slots.left ? el.slots.left.rotation : [0, 0, 0];
      el.slots.left.rotation[1] += 3.1416;
      el.slots['+x'] = {};
      Object.assign(el.slots['+x'], el.slots.left);
      delete el.slots.left;
    }

    // rename and rotate machine.right to machine.-x
    if (el.constructor === Object && ['cube', 'machine', 'pipeT', 'roller'].includes(el.asset) && el.slots?.right) {
      el.slots['-x'] = {};
      Object.assign(el.slots['-x'], el.slots.right);
      delete el.slots.right;
    }

    // rename and rotate machine.back to machine.-z
    if (el.constructor === Object && ['cube', 'machine'].includes(el.asset) && el.slots?.back) {
      el.slots.back.rotation = 'rotation' in el.slots.back ? el.slots.back.rotation : [0, 0, 0];
      el.slots.back.rotation[1] += 1.5708;
      el.slots['-z'] = {};
      Object.assign(el.slots['-z'], el.slots.back);
      delete el.slots.back;
    }

    // rename and rotate machine.front to machine.+z
    if (el.constructor === Object && ['cube', 'machine'].includes(el.asset) && el.slots?.front) {
      el.slots.front.rotation = 'rotation' in el.slots.front ? el.slots.front.rotation : [0, 0, 0];
      el.slots.front.rotation[1] += -1.5708;
      el.slots['+z'] = {};
      Object.assign(el.slots['+z'], el.slots.front);
      delete el.slots.front;
    }

    // rename and rotate machine.down to machine.-y
    if (el.constructor === Object && ['cube', 'machine'].includes(el.asset) && el.slots?.down) {
      el.slots.down.rotation = 'rotation' in el.slots.down ? el.slots.down.rotation : [0, 0, 0];
      el.slots.down.rotation[1] += 3.1416;
      el.slots['-y'] = {};
      Object.assign(el.slots['-y'], el.slots.down);
      delete el.slots.down;
    }
  })

  data.version = '0.8';
  return data;
}

function from_0_8_to_0_9(data) {
  recursive(data, (el) => {
    // rename 'triangle' => 'prism'
    if (
      el.constructor === Object &&
      ['pipeT', 'balloon', 'bell', 'pipeBent2x2', 'pipeBent3x3', 'pipeBent4x4', 'bentPipe5x5', 'bentPipe6x6', 'bentPipe7x7', 'bentPipe8x8', 'wheelCogBig', 'wheelBike', 'button', 'camera', 'wheelCar', 'connector', 'cube', 'decoupler', 'gearRack', 'pin', 'piston', 'pivot', 'prism', 'roller', 'wheelCogSmall', 'pipeStraight1', 'pipeStraightFifth', 'pipeStraightHalf', 'thrower', 'thruster', 'wheelTrain', 'wheelTrainInverted', 'triangle', 'twist', 'wing'].includes(el.asset) &&
      'rotation' in el)
    {
      let p = Math.round(el.rotation[1] / (Math.PI / 2));
      p = (p + 4) % 4; // result between 0 and 3
      el.pivot = p;  
      delete el.rotation;
    }
  })

  data.version = '0.9';
  return data;
}

function from_0_9_to_0_10(data) {
  recursive(data, (el) => {
    // Rename 'plasticColor' to 'color' parameter.
    if (el.constructor === Object && 'plasticColor' in el) {
      el.color = el.plasticColor;
      delete el.plasticColor;
    }
  })

  data.version = '0.10';
  return data;
}

function from_0_10_to_0_11(data) {
  recursive(data, (el) => {
    // rename 'wing' => 'wing5'
    if (el.constructor === Object && el.asset === 'wing')
      el.asset = 'wing5';
  })

  data.version = '0.11';
  return data;
}

function from_0_11_to_0_12(data) {
  // Fix 'editable' parameter type from bool to enum.
  recursive(data, (el) => {
    if (el.constructor === Object && 'editable' in el) {
      el.editable = el.editable === true ? 'true' : 'false';
    }
  })

  // Replace user-input '[Left|Right]*' by '$1'.
  recursive(data, (el) => {
    if (el.constructor === Object && 'controller' in el) {
      el.controller = el.controller.replace(/(Left|Right)(Up|Down|Left|Right)/g, function(match, p1, p2) {
        return p2;
      });
    }
  })

  // Move up: dominos, balls and marbles
  recursive(data, (el) => {
    if (el.constructor === Object && ['marble', 'ball', 'domino'].includes(el.asset) && 'position' in el)
      el.position = [el.position[0], el.position[1] + 0.5, el.position[2]]
  })

  data.version = '0.12';
  return data;
}

function from_0_12_to_0_13(data) {
  recursive(data, (el) => {
    // drop ballBearing.parameters.fixed
    if (el.constructor === Object && el.asset === 'ballBearing' && 'parameters' in el)
      delete el.parameters.fixed;
  })

  data.version = '0.13';
  return data;
}

function from_0_13_to_0_14(data) {
  // massive asset rename
  recursive(data, (el) => {
    if (el.constructor === Object && 'asset' in el) {
      switch(el.asset) {
        case 'bentPipe2x2':
          el.asset = 'pipeBent2x2'; break;
        case 'bentPipe3x3':
          el.asset = 'pipeBent3x3'; break;
        case 'bentPipe4x4':
          el.asset = 'pipeBent4x4'; break;
        case 'straightPipeFifth':
          el.asset = 'pipeStraightFifth'; break;
        case 'straightPipeHalf':
          el.asset = 'pipeStraightHalf'; break;
        case 'straightPipe1':
          el.asset = 'pipeStraight1'; break;
        case 'TPipe':
          el.asset = 'pipeT'; break;
        case 'bikeWheel':
          el.asset = 'wheelBike'; break;
        case 'carWheel':
          el.asset = 'wheelCar'; break;
        case 'cogSmall':
          el.asset = 'wheelCogSmall'; break;
        case 'cogBig':
          el.asset = 'wheelCogBig'; break;
        case 'trainWheel':
          el.asset = 'wheelTrain'; break;
        case 'trainWheelInverted':
          el.asset = 'wheelTrainInverted'; break;
        default:
      }
    }
  })

  data.version = '0.14';
  return data;
}

function from_0_14_to_0_15(data) {
  recursive(data, (el) => {
    if (el.constructor === Object && 'parameters' in el && 'ground-type' in el.parameters && el.parameters['ground-type'] === 'elevation-grid')
      el.parameters['ground-type'] = 'uneven';
  })

  data.version = '0.15';
  return data;
}

function from_0_15_to_0_16(data) {
  recursive(data, (el) => {
    // drop dashes
    if (el.constructor === Object && 'ground-size' in el) {
      el['groundSize'] = el['ground-size'];
      delete el['ground-size'];
    }
    if (el.constructor === Object && 'ground-material' in el) {
      el['groundMaterial'] = el['ground-material'];
      delete el['ground-material'];
    }
    if (el.constructor === Object && 'ground-type' in el) {
      el['groundType'] = el['ground-type'];
      delete el['ground-type'];
    }
    if (el.constructor === Object && 'water-level' in el) {
      el['waterLevel'] = el['water-level'];
      delete el['water-level'];
    }
    if (el.constructor === Object && 'buildable-area-x' in el) {
      el['buildableAreaX'] = el['buildable-area-x'];
      delete el['buildable-area-x'];
    }
    if (el.constructor === Object && 'buildable-area-y' in el) {
      el['buildableAreaY'] = el['buildable-area-y'];
      delete el['buildable-area-y'];
    }
    if (el.constructor === Object && 'buildable-area-z' in el) {
      el['buildableAreaZ'] = el['buildable-area-z'];
      delete el['buildable-area-z'];
    }
    if (el.constructor === Object && 'available-blocks' in el) {
      el['availableBlocks'] = el['available-blocks'];
      delete el['available-blocks'];
    }
  })

  data.version = '0.16';
  return data;
}

function from_0_16_to_0_17(data) {
  // Replace user-input '[Left|Right]*' by '$1'.
  recursive(data, (el) => {
    if (el.constructor === Object && 'controller' in el) {
      el.controller = el.controller.replace(/"SPEECH":"([^-]*)-(.*)"/g, '"SPEECH":"$2"');
    }
  })

  data.version = '0.17';
  return data;
}

function from_0_17_to_0_18(data) {
  // Traverse the entire data structure recursively.
  recursive(data, (el) => {
    if (el && typeof el === 'object' && 'asset' in el && typeof el.asset === 'string') {
      let asset = el.asset;
      let match;
      let defaultValues;
      let newAsset = asset; // By default, keep the current asset name

      // Check if asset matches brick%dx%dx%d pattern.
      if ((match = asset.match(/^brick(\d+)x(\d+)x(\d+)$/))) {
        newAsset = "brick";
        defaultValues = { sizeX: 3, sizeY: 1, sizeZ: 1 };
      }
      // Check if asset matches block%dx%dx%d pattern.
      else if ((match = asset.match(/^block(\d+)x(\d+)x(\d+)$/))) {
        newAsset = "block";
        defaultValues = { sizeX: 3, sizeY: 2, sizeZ: 1 };
      }
      // Check if asset matches ramp%dx%dx%d pattern.
      else if ((match = asset.match(/^ramp(\d+)x(\d+)x(\d+)$/))) {
        newAsset = "ramp";
        defaultValues = { sizeX: 5, sizeY: 1, sizeZ: 5 };
      }
      // Check if asset matches wall%dx%dx%d pattern.
      else if ((match = asset.match(/^wall(\d+)x(\d+)x(\d+)$/))) {
        newAsset = "block";
        defaultValues = { sizeX: 3, sizeY: 2, sizeZ: 1 };
      }

      // If a match was found, update the asset name and parameters.
      if (match) {
        // Note: For ramps the previous version had Y and Z inverted.
        const sizeX = parseInt(match[1], 10);
        const sizeY = parseInt(match[3], 10); // Inversion: Y is third captured group.
        const sizeZ = parseInt(match[2], 10); // Inversion: Z is second captured group.
        el.asset = newAsset;
        if (!('parameters' in el)) {
          el.parameters = {};
        }
        // Set the parameters only if they differ from the default values.
        if (!('sizeX' in el.parameters) && sizeX !== defaultValues.sizeX) {
          el.parameters.sizeX = sizeX;
        }
        if (!('sizeY' in el.parameters) && sizeY !== defaultValues.sizeY) {
          el.parameters.sizeY = sizeY;
        }
        if (!('sizeZ' in el.parameters) && sizeZ !== defaultValues.sizeZ) {
          el.parameters.sizeZ = sizeZ;
        }

        // For ramps, apply a shift of -2 along the local X axis.
        // Local X axis = [cos(yaw), 0, -sin(yaw)] so shift = -2 * that vector.
        if (newAsset === "ramp") {
          const pos = (Array.isArray(el.position)) ? el.position : [0, 0, 0];
          const rot = (Array.isArray(el.rotation)) ? el.rotation : [0, 0, 0];
          const yaw = rot[1] || 0; // assume rotation in radians.
          const shiftX = 2 * Math.cos(yaw);
          const shiftZ = 2 * (-Math.sin(yaw)); // equals 2*sin(yaw)
          // Update position accordingly:
          el.position = [ pos[0] + shiftX, pos[1], pos[2] + shiftZ ];
        }
      }
    }
  });

  data.version = '0.18';
  return data;
}
