/**
 * Evaluates a parametric expression.
 *
 * If the expression is an object with the key $expr, the string is evaluated using the given parameters.
 *
 * @param {string|object} expr - The expression as a string or { $expr: "..." }.
 * @param {object} parameters - Object containing parameter values.
 * @returns {any} The evaluated value.
 */
function interpretParametricExpression(expr, parameters) {
  // Retrieve the expression string if necessary
  const expressionStr =
    typeof expr === "object" && expr.hasOwnProperty("$expr")
      ? expr.$expr
      : expr;

  // Allowed mathematical functions
  const allowedMath = {
    sin: Math.sin,
    cos: Math.cos,
    tan: Math.tan,
    sqrt: Math.sqrt,
    pow: Math.pow,
    abs: Math.abs,
    // Add other allowed functions if needed.
  };

  // Construct the evaluation context by merging parameters and allowed functions
  const context = { ...allowedMath, ...parameters };
  const args = Object.keys(context);
  const values = Object.values(context);

  // Safely use new Function to evaluate the expression.
  // Ensure that the content of expressionStr is properly controlled.
  const evaluator = new Function(...args, `return (${expressionStr});`); // eslint-disable-line
  return evaluator(...values);
}

/**
 * Recursively interprets an asset field.
 *
 * This function handles:
 * - Primitive values (returned as-is)
 * - Objects of type { $expr: "..." } (evaluated via interpretParametricExpression)
 * - Arrays (processed element by element)
 * - Standard objects (each property is processed recursively)
 *
 * @param {any} value - The asset field value to interpret.
 * @param {object} parameters - Object containing parameter values.
 * @returns {any} The interpreted value.
 */
export function interpretAssetField(value, parameters) {
  if (parameters === undefined) return value;
  if (Array.isArray(value)) {
    // Case: an array - process each element.
    return value.map((item) => interpretAssetField(item, parameters));
  } else if (typeof value === "object" && value !== null) {
    // If it's an object and has the $expr key, evaluate it as an expression.
    if (value.hasOwnProperty("$expr")) {
      return interpretParametricExpression(value, parameters);
    }
    // Otherwise, it's a standard object - process each property recursively.
    const result = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        result[key] = interpretAssetField(value[key], parameters);
      }
    }
    return result;
  }
  // Primitive value (number, string, boolean, etc.)
  return value;
}
