// components/AdminJsonViewer.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import pako from "pako";
import { Box, Paper, TextField, Typography, Button } from "@mui/material";
import { firestore } from "../../firebase/config";

const AdminJsonViewer = () => {
  const { type, documentSlug } = useParams();
  const [jsonContent, setJsonContent] = useState(null);
  const [compressedContent, setCompressedContent] = useState(null);
  const [fullDocument, setFullDocument] = useState(null);
  const [error, setError] = useState(null);
  const [isCopiedJson, setIsCopiedJson] = useState(false);
  const [isCopiedCompressed, setIsCopiedCompressed] = useState(false);

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const collectionRef = collection(firestore, type);
        const docQuery = query(
          collectionRef,
          where("slug", "==", documentSlug)
        );
        const querySnapshot = await getDocs(docQuery);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const docData = docSnap.data();
          setFullDocument(docData);
          const data = docData.data;

          // Decompress the JSON data
          const compressedBuffer = Uint8Array.from(atob(data), (c) =>
            c.charCodeAt(0)
          );
          const decompressedBuffer = pako.inflate(compressedBuffer);
          const jsonData = JSON.parse(
            new TextDecoder().decode(decompressedBuffer)
          );

          setJsonContent(jsonData);
          setCompressedContent(data);
        } else {
          setError(`Document with slug "${documentSlug}" not found in ${type}`);
        }
      } catch (err) {
        console.error("Error fetching JSON data:", err);
        setError("Failed to fetch or parse the document data.");
      }
    };

    fetchJsonData();
  }, [type, documentSlug]);

  const handleCopyToClipboard = (content, setCopiedState) => {
    navigator.clipboard.writeText(content);
    setCopiedState(true);
    setTimeout(() => setCopiedState(false), 2000);
  };

  const handleJsonEdit = (event) => {
    try {
      const updatedJson = JSON.parse(event.target.value);
      setJsonContent(updatedJson);
      // Regenerate compressed content from updated JSON
      const compressedBuffer = pako.deflate(JSON.stringify(updatedJson));
      const base64Compressed = btoa(
        String.fromCharCode(...new Uint8Array(compressedBuffer))
      );
      setCompressedContent(base64Compressed);
    } catch (error) {
      console.error("Invalid JSON format:", error);
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        JSON Viewer
      </Typography>

      {/* Full document preview */}
      {fullDocument && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">Full Document Preview</Typography>
          <TextField
            fullWidth
            multiline
            value={JSON.stringify(fullDocument, null, 2)}
            InputProps={{ readOnly: true }}
            variant="outlined"
            sx={{ fontFamily: "monospace" }}
          />
        </Paper>
      )}

      {/* Compressed content (read-only) */}
      {compressedContent && (
        <Paper sx={{ position: "relative", p: 2, mb: 2 }}>
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              handleCopyToClipboard(compressedContent, setIsCopiedCompressed)
            }
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 2 }}
          >
            {isCopiedCompressed ? "Copied!" : "Copy Compressed"}
          </Button>
          <TextField
            fullWidth
            multiline
            value={compressedContent}
            InputProps={{ readOnly: true }}
            variant="outlined"
            sx={{ fontFamily: "monospace", mt: 4 }}
          />
        </Paper>
      )}

      {/* JSON content (editable) */}
      {jsonContent && (
        <Paper sx={{ position: "relative", p: 2 }}>
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              handleCopyToClipboard(
                JSON.stringify(jsonContent, null, 2),
                setIsCopiedJson
              )
            }
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 2 }}
          >
            {isCopiedJson ? "Copied!" : "Copy JSON"}
          </Button>
          <TextField
            fullWidth
            multiline
            value={JSON.stringify(jsonContent, null, 2)}
            onChange={handleJsonEdit}
            variant="outlined"
            sx={{ fontFamily: "monospace", mt: 4 }}
          />
        </Paper>
      )}
    </Box>
  );
};

export default AdminJsonViewer;
